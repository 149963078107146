import React, { useEffect, useState } from 'react'
import Container from '../components/common/PageContainer'
import Error from '../components/common/buttons/ErrorConnectWalletButton'
import LoadingAnimation from '../components/common/loading/LoadingAnimation'
import { useGetNftsForCollectionQuery } from '../state/opensea'
import { FramesContractDetails } from '../lib/constants'
import useGetTotalFrameSupply from '../hooks/useGetTotalFrameSupply'
import FramesNftCard from '../components/common/collection/FramesNftCard'
import FramesLoadingCard from '../components/common/collection/FramesLoadingCard'
import { useHistory } from 'react-router-dom'
import FrameTokenInput from '../components/common/collection/FrameTokenInput'
import { Box } from '@mui/material'
import VirtualizedGrid from '../components/common/VirtualizedGrid'
const LOAD_LIMIT = 10

const CollectionPage = () => {
  return (
    <Container>
      <Content />
    </Container>
  )
}

const Content = () => {
  const {
    isLoading,
    value: totalSupply,
    error,
    execute: getTotalSupply
  } = useGetTotalFrameSupply(true)
  const history = useHistory()

  const [errorLoadingNfts, setErrorLoadingNfts] = useState(null)
  const [tokenIdsToFetch, setTokenIdsToFetch] = useState([])
  const [allNftsMap, setAllNftsMap] = useState(new Map())

  const {
    data,
    isFetching,
    error: openseaError
  } = useGetNftsForCollectionQuery(
    {
      contractAddress: FramesContractDetails.id,
      tokenIds: tokenIdsToFetch,
      limit: tokenIdsToFetch.length
    },
    {
      skip: !totalSupply
        ? !totalSupply
        : !tokenIdsToFetch || tokenIdsToFetch.length === 0
    }
  )
  useEffect(() => {
    if (isLoading) {
      setErrorLoadingNfts(null)
      return
    }

    if (error) {
      console.error(error)
      setErrorLoadingNfts('Error loading Frames Collection: ' + error.message)
      return
    }
    if (!isLoading && !totalSupply) {
      getTotalSupply()
    } else if (totalSupply) {
      const tokenIds = [...Array(LOAD_LIMIT).keys()]
      setTokenIdsToFetch(tokenIds)
    }

    return () => {}
  }, [isLoading, totalSupply, error, getTotalSupply])

  const handleVisibleRangeChanged = (startIndex, endIndex) => {
    const tokenIds = [...Array(parseInt(endIndex + 1)).keys()].slice(
      startIndex,
      endIndex + 1
    )
    const filteredIds = tokenIds.filter(tokenId => !allNftsMap.has(tokenId))
    console.log('filteredIds', filteredIds)
    if (filteredIds.length !== 0) {
      setTokenIdsToFetch(filteredIds)
    }
  }

  useEffect(() => {
    if (isFetching) {
      console.log('isFetching', tokenIdsToFetch)
      return
    }
    if (openseaError) {
      setErrorLoadingNfts(
        'Error loading Frames Collection: ' + openseaError.message
      )
      return
    }

    if (!data || !data.length) return

    let newMap

    if (allNftsMap.length === 0) {
      newMap = new Map(
        data.map(element => [parseInt(element.tokenID), element])
      )
    } else {
      newMap = new Map(allNftsMap)
      for (let index = 0; index < data.length; index++) {
        const element = data[index]
        newMap.set(parseInt(element.tokenID), element)
      }
    }

    setAllNftsMap(newMap)

    return () => {}
  }, [isFetching, data, openseaError])

  return isLoading ? (
    <LoadingAnimation />
  ) : errorLoadingNfts ? (
    <Error text={errorLoadingNfts} />
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        height: '100%',
        alignItems: 'center'
      }}
    >
      <FrameTokenInput
        sx={{
          mb: '1rem'
        }}
        maxTokenId={totalSupply - 1}
        onGoToTokenDetails={tokenId => {
          console.log('tokenId', tokenId)
          history.push(`/frame/${tokenId}`)
        }}
      />
      <VirtualizedGrid
        onVisibleRangeChanged={handleVisibleRangeChanged}
        style={{ height: '100%', width: '100%', flex: 1 }}
        totalCount={totalSupply}
        overscan={20}
        loadingViewForIndex={index => {
          return <FramesLoadingCard text={`Frame #${index}`} />
        }}
        itemViewForIndex={index => {
          return (
            <>
              {!allNftsMap || allNftsMap.get(index) === undefined ? (
                <FramesLoadingCard
                  text={` Frame #${index}`}
                  onItemClick={() => {
                    history.push(`/frame/${index}`)
                  }}
                />
              ) : (
                <FramesNftCard
                  sx={{ flex: 1, height: '100%', width: '100%' }}
                  nft={allNftsMap.get(index)}
                  onItemClick={(content, metadata) => {
                    history.push(`/frame/${content.tokenID}`)
                  }}
                />
              )}
            </>
          )
        }}
      />
    </Box>
  )
}

export default CollectionPage
