import React, { useState, useEffect } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Typography, Box, Grid } from '@mui/material'
import { useTheme } from '@mui/styles'
import LoadingButton from '@mui/lab/LoadingButton'
import Link from '@mui/material/Link'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Tooltip from '@mui/material/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import IconButton from '@mui/material/IconButton'
import CancelIcon from '@mui/icons-material/Cancel'
import { red } from '@mui/material/colors'
const CONTENTS_PLACEHOLDER = '---'

export default function PreviewDetails(props) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const [canUnmount, setCanUnmount] = useState(false)
  const [canMount, setCanMount] = useState(true)
  const [canUnbind, setCanUnbind] = useState(false)

  useEffect(() => {
    async function onFrameMetadataUpdated() {
      setCanUnbind(false)
      setCanUnmount(false)
      setCanMount(true)
      if (!props.selectedFrameMetadata) return

      props.selectedFrameMetadata.attributes.forEach(attribute => {
        if (attribute.trait_type === 'Framing Method') {
          if (attribute.value === 'Mounted') {
            setCanUnmount(true)
          } else if (attribute.value === 'Bound') {
            setCanUnbind(true)
            setCanUnmount(false)
            setCanMount(false)
          }
        }
      })
    }

    onFrameMetadataUpdated()
  }, [props.selectedFrameMetadata])

  const createContentDetails = () => (
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={0}
      sx={{
        alignSelf: 'center',
        width: '100%',
        paddingBottom: { xs: '0px', sm: '24px' }
      }}
    >
      <Grid item xs={6}>
        {createTextRow(
          'FRAME',
          props.selectedFrameMetadata && props.selectedFrameMetadata.name
            ? props.selectedFrameMetadata.name
            : props.selectedFrame
            ? `Frame #${props.selectedFrame.tokenID}`
            : CONTENTS_PLACEHOLDER,
          props.selectedFrame ? `/frame/${props.selectedFrame.tokenID}` : null,
          props.selectedFrame,
          props.onCancelFrameClick
        )}
      </Grid>
      <Grid item xs={6}>
        {createTextRow(
          'CONTENTS',
          props.selectedNftMetadata && props.selectedNftMetadata.name
            ? props.selectedNftMetadata.name
            : props.selectedNft
            ? `${props.selectedNft.contract.name} #${props.selectedNft.tokenID}`
            : CONTENTS_PLACEHOLDER,
          props.selectedNftMetadata && props.selectedNftMetadata.external_url
            ? props.selectedNftMetadata.external_url
            : props.selectedNft
            ? `https://opensea.io/assets/${props.selectedNft.contract.id}/${props.selectedNft.tokenID}`
            : null,
          props.selectedNft,
          props.onCancelNftClick
        )}
      </Grid>
    </Grid>
  )

  const createTextRow = (
    key,
    value,
    link = null,
    showCancel = true,
    onCancelClick
  ) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography
        sx={{
          textAlign: 'left',
          fontFamily: 'Roboto',
          fontWeight: 500
        }}
        variant="body2"
        color="primary"
      >
        {key}
      </Typography>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          alignContent: 'center'
        }}
      >
        <Typography
          sx={{
            flex: 1,
            marginTop: '12px',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 300
          }}
          variant="h5"
          noWrap
        >
          {link ? (
            <Link
              {...(link.indexOf('http') !== -1
                ? {
                    href: link,
                    target: '_blank',
                    rel: 'noopener'
                  }
                : { href: link })}
              color="inherit"
              underline="hover"
            >
              {value}
            </Link>
          ) : (
            value
          )}
        </Typography>
        {showCancel && (
          <IconButton
            onClick={onCancelClick}
            color="primary"
            aria-label="upload picture"
            component="label"
            sx={{
              color: red[700]
            }}
          >
            <CancelIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  )

  return (
    <Card
      elevation={0}
      sx={{
        ...(props.sx
          ? {
              ...props.sx
            }
          : {}),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.8)'
      }}
    >
      <CardContent
        sx={{
          padding: { xs: '12px', sm: '24px', md: '36px' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'stretch',
          width: '100%'
        }}
      >
        {createContentDetails()}

        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ paddingBottom: { xs: '12px', sm: '30px' } }}
        >
          <Grid item xs>
            <LoadingButton
              style={{ width: '100%' }}
              loading={props.mountLoading || props.bindStatusLoading}
              onClick={props.onMountClick}
              disabled={
                canMount ? !props.selectedNft || !props.selectedFrame : true
              }
              variant="contained"
            >
              Mount contents
            </LoadingButton>
          </Grid>
          <Grid item xs>
            <LoadingButton
              style={{ width: '100%' }}
              loading={props.unmountLoading || props.bindStatusLoading}
              onClick={props.onUnmountClick}
              disabled={!canUnmount}
              variant="contained"
            >
              unmount contents
            </LoadingButton>
          </Grid>
          <Grid item xs={1}>
            <Tooltip
              placement="top"
              arrow
              title={`Mount your NFTs contents to the Frame while keeping both the NFT and
            the Frame as individual NFTs (gasless!)`}
            >
              <IconButton>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            paddingBottom: { xs: '12px', sm: '24px', md: '36px' }
          }}
        >
          <Grid item xs>
            <LoadingButton
              style={{ width: '100%' }}
              loading={props.bindLoading || props.bindStatusLoading}
              onClick={props.onBindClick}
              disabled={
                props.chainId !== 1 ||
                !props.selectedNft ||
                !props.selectedFrame
              }
              // disabled={true}
              variant="contained"
            >
              Bind contents
            </LoadingButton>
          </Grid>
          <Grid item xs>
            <LoadingButton
              style={{ width: '100%' }}
              loading={props.bindLoading || props.bindStatusLoading}
              onClick={props.onUnbindClick}
              disabled={!canUnbind}
              variant="contained"
            >
              unbind contents
            </LoadingButton>
          </Grid>
          <Grid item xs={1}>
            <Tooltip
              placement="top"
              arrow
              title={` Bind your NFT to the Frame to create a single NFT, compounding their
            rarities together!`}
            >
              <IconButton>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
