import * as anchor from '@project-serum/anchor'

export const checkOwnsSolanaToken = async (tokenMint, addressToCheck) => {
  const connection = new anchor.web3.Connection(
    'https://api.metaplex.solana.com/'
  )

  const largestAccounts = await connection.getTokenLargestAccounts(
    new anchor.web3.PublicKey(tokenMint)
  )
  const largestAccountInfo = await connection.getParsedAccountInfo(
    largestAccounts.value[0].address
  )
  console.log(largestAccountInfo.value.data.parsed.info.owner)

  return addressToCheck === largestAccountInfo.value.data.parsed.info.owner
}
