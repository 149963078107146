import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Grid } from '@mui/material'
import Fade from 'react-reveal/Fade'

export default function HeroContent (props) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <Grid
      {...props}
      container
      rowSpacing={0}
      columnSpacing={{ xs: 0, md: 6 }}
      px={{ xs: 2, md: 6 }}
      py={2}
      direction='row'
      justify={isMobile ? 'center' : 'space-between'}
      alignItems={isMobile ? 'center' : 'space-evenly'}
    >
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        item
        md={6}
        sm={6}
        xs={12}
      >
        <Grid item xs style={{}}>
          <Fade right>
            {props.imageSrc.indexOf('mp4') !== -1 ? (
              <video
                style={{
                  width: '100%',
                  objectFit: 'contain'
                }}
                loop='true'
                muted='true'
                autoplay='true'
                playsinline='true'
                controls='true'
              >
                <source src={props.imageSrc} type='video/mp4' />
              </video>
            ) : (
              <img
                style={{
                  width: '100%',
                  objectFit: 'contain'
                }}
                src={props.imageSrc}
                alt=''
              />
            )}
          </Fade>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={6}
        md={6}
        direction='row'
        justifyContent='center'
        alignItems='center'
        alignContent='center'
      >
        <Grid item>{props.children}</Grid>
      </Grid>
    </Grid>
  )
}
