import React from 'react'
import styled from 'styled-components'
import { Icon, Button } from '@mui/material'

import DiscordLogo from '../../../assets/images/logos/discord.svg'

const DiscordButton = () => {
  return (
    <StyledButton
      variant="contained"
      disableElevation
      fullWidth
      align="center"
      endIcon={<DiscordIcon />}
      component="a"
      target="_blank"
      href="https://discord.gg/murall"
    >
      Join our Discord
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`

const DiscordIcon = () => (
  <Icon>
    <img alt="twitter" src={DiscordLogo} />
  </Icon>
)

export default DiscordButton
