import { Typography, Card, CardActionArea, Avatar, Grid } from '@mui/material'
import NftLoadingView from '../mounting/NftLoadingView'
import FramesLogo from '../../../assets/images/logos/FRAME_small.png'

export default function FramesLoadingCard({ text, onItemClick }) {
  return (
    <Card elevation={4}>
      <CardActionArea
        onClick={() => {
          onItemClick && onItemClick()
        }}
      >
        <NftLoadingView sx={{ aspectRatio: '1/1', width: '100%' }} />

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Avatar
              alt="Frames"
              src={FramesLogo}
              variant="square"
              sx={{
                width: theme => theme.spacing(3),
                height: theme => theme.spacing(3)
              }}
            />
          </Grid>
          <Grid item>
            <Typography
              sx={{ p: 1.5, fontFamily: 'Roboto', fontWeight: 300 }}
              variant="h6"
              component="p"
              color="textPrimary"
            >
              {text}
            </Typography>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  )
}
