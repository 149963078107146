import { constructFrameNftWithBaseSvg } from './ConstructFrameNftWithBaseSvgUsecase'
import { constructBaseSvgStringForTraitHash } from './ConstructBaseSvgStringForTraitHashUsecase'

export const constructFrameNft = async (
  web3,
  contractAddress,
  tokenId,
  chainId = 1,
  traitHash
) => {
  const baseSvgString = await constructBaseSvgStringForTraitHash(traitHash)
  const svg = await constructFrameNftWithBaseSvg(
    web3,
    contractAddress,
    tokenId,
    chainId,
    baseSvgString
  )

  return svg
}
