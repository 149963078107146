import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { UnsupportedChainIdError } from '@web3-react/core'
import Container from '../components/common/PageContainer'
import ConnectWalletModal from '../components/common/wallet/modal'
import Error from '../components/common/buttons/ErrorConnectWalletButton'
import ConnectWalletButton from '../components/common/buttons/ConnectWalletButton'
import { useActiveWeb3React } from '../hooks/web3'
import LoadingAnimation from '../components/common/loading/LoadingAnimation'
import FramePageContent from '../components/common/frame/FramePageContent'
import { switchToEthereum } from '../usecase/SwitchToEthereumUsecase'
import { useGetFrameTokenFullMetadata } from '../hooks/useGetFrameTokenFullMetadata'

const FramePage = () => {
  const [openModal, setOpenModal] = useState(false)
  return (
    <Container>
      <Content open={() => setOpenModal(true)} />
      <ConnectWalletModal
        isOpen={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </Container>
  )
}

const Content = ({ open }) => {
  const { library, error, account } = useActiveWeb3React()

  if (error)
    return (
      <Error
        onClick={() => {
          open()
          switchToEthereum(account)
        }}
        text={
          error instanceof UnsupportedChainIdError
            ? 'Unsupported Network'
            : 'Error'
        }
      />
    )

  if (library) {
    return <FrameContainer />
  }

  return <ConnectWalletButton onClick={open} text='Connect Your Wallet' />
}

const FrameContainer = () => {
  let { id } = useParams()

  const [errorLoading, setErrorLoading] = useState(null)
  const [initialLoading, setInitialLoading] = useState(true)

  const {
    execute: executeMetadata,
    isLoading: isLoadingFrame,
    value: frameData,
    error: errorData
  } = useGetFrameTokenFullMetadata(id, false)

  useEffect(() => {
    if (isLoadingFrame) return
    if (errorData) {
      console.error(errorData)
      setErrorLoading(
        errorData.message.indexOf('Invalid Token ID') !== -1
          ? `Token #${id} does not exist!\nPlease use an existing token id`
          : errorData.message
      )
      return
    }
    if (!frameData) return

    return () => {}
  }, [isLoadingFrame, frameData, errorData])

  useEffect(() => {
    async function onLoad () {
      if (!isLoadingFrame) {
        setInitialLoading(true)
        await executeMetadata()
        setInitialLoading(false)
      }
    }

    onLoad()

    return () => {}
  }, [id])

  return isLoadingFrame || initialLoading ? (
    <LoadingAnimation />
  ) : errorLoading ? (
    <Error text={errorLoading} />
  ) : (
    <FramePageContent
      frame={frameData.frame}
      frameMetadata={frameData.metadata}
    />
  )
}

export default FramePage
