import React from 'react'
import styled from 'styled-components'
import { Grid } from '@mui/material'

import SectionTitle from '../components/common/SectionTitle'
import StyledTimeline from '../components/common/StyledTimeline'
import FrameExample from '../assets/images/frame-example.png'
import WidthLimiter from '../components/common/WidthLimiter'

const TIMELINE_ITEMS = [
  {
    timelineValue: '10%',
    description: 'We teach you our secret handshake'
  },
  { timelineValue: '25%', description: '1 FRAME giveaway' },
  {
    timelineValue: '50%',
    description: '2 FRAMES giveaway + add support for Optimism'
  },
  {
    timelineValue: '75%',
    description: '3 FRAMES giveaway + add support for Arbitrum'
  },
  {
    timelineValue: '100%',
    description: '4 FRAMES giveaway + add support for user voted network'
  }
]

const Roadmap = () => {
  return (
    <WidthLimiter>
      <Container>
        <SectionTitle title='Mint Roadmap' />
        <Grid container spacing={2} px={{ xs: 0, md: 16 }} pb={16}>
          <Grid item md={8} xs={12}>
            <StyledTimeline items={TIMELINE_ITEMS} />
          </Grid>
          <Grid
            item
            md={4}
            sx={{
              display: { xs: 'none', md: 'block' }
            }}
          >
            <Image src={FrameExample} />
          </Grid>
        </Grid>
      </Container>
    </WidthLimiter>
  )
}

const Container = styled.div`
  display: flex:
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
`

export default Roadmap
