import React, { useState, useEffect } from 'react'
import { Typography, Box, CardActionArea } from '@mui/material'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import { getAndProcessTokenMetadata } from '../../../usecase/GetAndProcessTokenMetadataUseCase'
import { processTokenMetadata } from '../../../usecase/ProcessTokenMetadataUseCase'
import { useWeb3React } from '@web3-react/core'
import NftLoadingView from './NftLoadingView'

export default function NftContainer ({
  nft,
  selected = false,
  onItemClick = null,
  needsRefresh = false,
  sx = null
}) {
  const [metadata, setMetadata] = useState(null)
  const [displayImage, setDisplayImage] = useState(null)
  const [displayImageType, setDisplayImageType] = useState(null)
  const [loading, setLoading] = useState(false)

  const { library } = useWeb3React()

  useEffect(() => {
    async function onLoad () {
      if (metadata && !needsRefresh) {
        return
      }
      if (library) {
        setLoading(true)
        let nftToFetch = nft
        if (nftToFetch.asset_contract) {
          // the nft is from opensea and contains metadata already
          try {
            const processedMetadata = await processTokenMetadata(nftToFetch)
            setMetadata(nftToFetch)
            setDisplayImageType(processedMetadata.mimeType)
            setDisplayImage(processedMetadata.display_url)
            setLoading(false)
          } catch (error) {
            console.error(error)
            setLoading(false)
          }
        } else {
          try {
            const theMetadata = await getAndProcessTokenMetadata(
              library,
              nftToFetch.contract.id,
              nftToFetch.tokenID
            )
            setMetadata(theMetadata.metadata)
            setDisplayImageType(theMetadata.mimeType)
            setDisplayImage(theMetadata.display_url)
            setLoading(false)
          } catch (error) {
            console.error(error)
            setLoading(false)
          }
        }
      }
    }

    onLoad()
    // Remove event listener on cleanup
    return () => {}
  }, [library, nft, needsRefresh])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        ...(sx
          ? {
              ...sx
            }
          : {})
      }}
    >
      {loading ? (
        <NftLoadingView />
      ) : (
        metadata && (
          <Card elevation={0}>
            <CardActionArea
              onClick={() => {
                onItemClick && onItemClick(nft, metadata)
              }}
            >
              <CardMedia
                sx={{
                  aspectRatio: '1/1'
                }}
                component={
                  displayImageType.startsWith('video') ? 'video' : 'img'
                }
                src={displayImage}
                title={metadata.name}
                alt={metadata.name}
                autoPlay={true}
                loop={true}
                muted={true}
              />
              <Box
                sx={{
                  position: 'absolute',

                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  paddingBottom: '0px',
                  paddingLeft: '0px',
                  paddingTop: '0px'
                }}
                style={{
                  background: selected
                    ? `radial-gradient(circle at bottom right,
                                #e91bfa00,
                                #e91bfaBF)`
                    : `radial-gradient(circle at bottom right,
                                #26262B00,
                                #26262BBF)`
                }}
              >
                <Typography
                  gutterBottom
                  variant='body'
                  component='p'
                  color='textPrimary'
                  style={{ paddingTop: '12px', paddingLeft: '12px' }}
                >
                  {metadata.name}
                </Typography>
                <Typography
                  display='block'
                  variant='caption'
                  color='text.secondary'
                  style={{ paddingLeft: '12px', color: '#fff' }}
                >
                  {nft.contract.name}
                </Typography>
              </Box>
            </CardActionArea>
          </Card>
        )
      )}

      {loading ||
        (!metadata && (
          <Card elevation={0} sx={{}}>
            <CardActionArea
              onClick={() => {
                onItemClick && onItemClick(nft, metadata)
              }}
            >
              <CardMedia
                sx={{
                  aspectRatio: '1/1'
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    paddingBottom: '0px',
                    paddingLeft: '0px',
                    paddingTop: '0px'
                  }}
                  style={{
                    background: selected
                      ? `radial-gradient(circle at bottom right,
                                #e91bfa00,
                                #e91bfaBF)`
                      : `radial-gradient(circle at bottom right,
                                #26262B00,
                                #26262BBF)`
                  }}
                >
                  <Typography
                    gutterBottom
                    variant='body'
                    component='p'
                    color='textPrimary'
                    style={{ paddingTop: '12px', paddingLeft: '12px' }}
                  >
                    {nft.tokenID}
                  </Typography>
                  <Typography
                    display='block'
                    variant='caption'
                    color='text.secondary'
                    style={{ paddingLeft: '12px', color: '#fff' }}
                  >
                    {nft.contract.name}
                  </Typography>
                </Box>
              </CardMedia>
            </CardActionArea>
          </Card>
        ))}
    </Box>
  )
}
