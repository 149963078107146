import React from 'react'
import styled from 'styled-components'

import ClickAwayListener from '@mui/material/ClickAwayListener'
import MenuToggle from './MenuToggle'
import NavLink from '../../common/Navbar/NavLink'
import Fade from 'react-reveal/Fade'
import Badge from '../../common/Badge'

const NavLinks = ({ isOpen, setOpen }) => {
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Container>
        <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />

        <Fade
          top
          cascade
          collapse
          when={isOpen}
          mountOnEnter
          unmountOnExit
          distance={isOpen ? '100%' : '120%'}
          duration={600}
        >
          <LinksWrapper onClick={() => setOpen(false)}>
            <NavLink title='DETAILS' to='/#details' />
            {/* <NavLink title='MINT' to='/mint' /> */}
            <NavLink title='NUMBERS' to='/#numbers' />
            <NavLink title='ROADMAP' to='/#roadmap' />
            <NavLink title='FAQ' to='/#faq' />
            <NavLink title='COLLECTION' to='/collection' />
            <NavLink title='MOUNT' to='/mount' />
            <Badge
              style={{ justifyContent: 'center' }}
              right={105}
              badgeContent='NEW'
              color='primary'
            >
              <NavLink title='MINT' to='/mint' />
              {/* <NavLink title='PRESALE' to='/presalemint' /> */}
            </Badge>
          </LinksWrapper>
        </Fade>
      </Container>
    </ClickAwayListener>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

const LinksWrapper = styled.ul`
  border-bottom: 2px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 5px;
  z-index: -1;
  margin: 0;
  padding: ${({ theme }) => theme.spacing(1)} 0;
  display: flex;
  list-style: none;
  background: ${({ theme }) => theme.palette.background.default};
  width: 100%;
  flex-direction: column;
  position: absolute;
  top: 60px;
  left: 0;
`

export default NavLinks
