import { useState, useEffect, useCallback } from 'react'
import { useMurAllFrameContract } from './useContract'
import { useWeb3React } from '@web3-react/core'

export const useUnbindContentsFromFrame = (
  account,
  frameTokenID,
  immediate = true
) => {
  const { library } = useWeb3React()
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState(null)
  const [error, setError] = useState(null)

  const murallFrameContract = useMurAllFrameContract()

  const execute = useCallback(async () => {
    if (!library || !murallFrameContract || !frameTokenID) return

    setIsLoading(true)
    setValue(null)
    setError(null)
    let result
    try {
      result = await murallFrameContract.methods
        .removeFrameContents(frameTokenID)
        .send({ from: account })

      setValue(result)
    } catch (err) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
    return result
  }, [murallFrameContract, account, frameTokenID])

  useEffect(() => {
    if (immediate) {
      execute()
    }
  }, [execute, immediate])
  return { execute, isLoading, value, error }
}

export default useUnbindContentsFromFrame
