import React from 'react'
import { ButtonGroup } from '@mui/material'

import SocialLink from './links/Social'

import DiscordLogo from '../../assets/images/logos/discord.svg'
import TwitterLogo from '../../assets/images/logos/twitter.svg'
import TelegramLogo from '../../assets/images/logos/telegram.svg'
import InstagramLogo from '../../assets/images/logos/instagram.svg'
import OpenSeaLogo from '../../assets/images/logos/opensea_logo_white.svg'

export default function SocialLinks() {
  return (
    <ButtonGroup
      disableElevation
      disableRipple
      variant="string"
      size="large"
      color="inherit"
    >
      <SocialLink
        logo={DiscordLogo}
        altText="discord"
        href="https://discord.gg/murall"
      />
      <SocialLink
        logo={TwitterLogo}
        altText="twitter"
        href="https://twitter.com/FramesByMurAll"
      />
      <SocialLink
        logo={TelegramLogo}
        altText="telegram"
        href="https://t.me/FramesByMurAll"
      />
      <SocialLink
        logo={InstagramLogo}
        altText="instagram"
        href="https://www.instagram.com/murall.art/"
      />
      <SocialLink
        logo={OpenSeaLogo}
        altText="opensea"
        href="https://opensea.io/collection/frames-by-murall"
      />
    </ButtonGroup>
  )
}
