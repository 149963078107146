import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

const FormBackground = ({ sx, children, slantFlipped }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <Card
      elevation={0}
      sx={{
        minWidth: 275,
        padding: isMobile ? '24px' : '48px',
        ...sx
      }}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
        {children}
      </CardContent>
    </Card>
  )
}

export default FormBackground
