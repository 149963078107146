import React from 'react'
import styled from 'styled-components'
import { Parallax, Background } from 'react-parallax'
import Fade from 'react-reveal/Fade'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Typography } from '@mui/material'
import HeroContent from '../components/common/HeroContent'
import DiscordButton from '../components/common/buttons/Discord'
import OpenseaButton from '../components/common/buttons/OpenSea'
import ScrollDownButton from '../components/common/buttons/ScrollDown'
import ThinkOutsideTheBoxImage from '../assets/images/think-outside-the-box.svg'
import DefaultContainer from '../components/DefaultContainer'
import LazyImage from '../components/common/LazyImage'
import PresaleMintButton from '../components/common/buttons/PresaleMint'

export default function FramesHero (props) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return (
    <Parallax strength={600}>
      <Background>
        <LazyImage
          src={
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII='
          }
          lqip={
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII='
          }
          style={{
            width: '100vw',
            height: '100vh',
            minHeight: '100vh',
            objectPosition: 'center top',
            objectFit: 'cover'
          }}
        />
      </Background>
      <DefaultContainer
        style={{
          maxWidth: props.maxContentWidth
        }}
      >
        <HeroContent
          imageSrc={'cross_chain_promo.mp4'}
          style={{ minHeight: '100vh' }}
        >
          <Fade bottom cascade>
            <Tagline
              src={ThinkOutsideTheBoxImage}
              alt='think outside the box'
              isMobile={isMobile}
            />
            <Description
              variant={isMobile ? 'h5' : 'h4'}
              component='p'
              align='center'
              gutterBottom
              isMobile={isMobile}
            >
              4,444 first of its kind NFT Frames with cross-chain mounting
            </Description>
            <Typography
              sx={{
                fontWeight: 300,
                color: theme => theme.palette.primary.contrastText
              }}
              variant='h5'
              align='center'
              gutterBottom
            >
              Public minting NOW LIVE!
            </Typography>
            <PresaleMintButton />
            <DiscordButton />
            <OpenseaButton />
          </Fade>
        </HeroContent>
      </DefaultContainer>
      {!isMobile && <ScrollDownButton section='details' />}
    </Parallax>
  )
}

const Tagline = styled.img`
  width: 100%;
  object-fit: contain;
  margin: 0;
  margin-top: ${({ theme, isMobile }) => (isMobile ? 0 : theme.spacing(6))};
  margin-bottom: ${({ theme, isMobile }) =>
    isMobile ? theme.spacing(3) : theme.spacing(3)};
  padding: 0;
`

const Description = styled(Typography)`
  width: 100%;
  font-weight: 300;
  margin-bottom: ${({ theme, isMobile }) =>
    isMobile ? theme.spacing(2) : theme.spacing(6)};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  text-shadow: 2px 2px 5px black;
`
