import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import LoadingAnimation from '../loading/LoadingAnimation'
import {
  replaceIpfsIfNeeded,
  getSvgCodeFromFile,
  IPFS_GATEWAY_PINATA_MURALL
} from '../../../lib/helpers.js'
import { constructBaseSvgStringForTraitHash } from '../../../usecase/ConstructBaseSvgStringForTraitHashUsecase'
import { constructFrameNft } from '../../../usecase/ConstructFrameNftUsecase'
import { useActiveWeb3React } from '../../../hooks/web3'

export default function FramePreview (props) {
  const { library } = useActiveWeb3React()
  const [loadingBase, setLoadingBase] = useState(false)

  const [unmountedSvg, setUnmountedSvg] = useState('')

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    async function onLoad () {
      setLoadingBase(true)

      if (signal.aborted) {
        return
      }

      let svgCode
      if (props.selectedFrameMetadata.animation_url) {
        svgCode = await getSvgCodeFromFile(
          replaceIpfsIfNeeded(props.selectedFrameMetadata.animation_url, IPFS_GATEWAY_PINATA_MURALL)
        )
        svgCode = svgCode.substring(
          svgCode.indexOf('<svg'),
          svgCode.lastIndexOf('</svg>') + 6
        )
      } else {
        svgCode = await constructFrameNft(
          library,
          null,
          0,
          1,
          props.selectedFrame.traitHash
        )

        svgCode = svgCode.svg
      }

      setUnmountedSvg(svgCode)
      setLoadingBase(false)
    }

    onLoad()
    // Remove event listener on cleanup
    return () => controller.abort()
  }, [props.selectedFrameMetadata]) // Empty array ensures that effect is only run on mount

  const createImage = frame => (
    <span
      dangerouslySetInnerHTML={{
        __html: frame
      }}
    />
  )

  return (
    <Grid
      sx={{
        ...(props.sx
          ? {
              ...props.sx
            }
          : {})
      }}
      container
      spacing={2}
      direction={'column'}
      justifyContent='space-between'
      alignItems='stretch'
    >
      {loadingBase ? (
        <Grid
          container
          item
          xs
          direction={'row'}
          justifyContent='center'
          alignItems='center'
        >
          <Grid item xs>
            <LoadingAnimation />
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid item xs>
            {createImage(unmountedSvg)}
          </Grid>
        </>
      )}
    </Grid>
  )
}
