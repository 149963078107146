import { useState, useEffect, useCallback } from 'react'
import { useMurAllFrameContract } from './useContract'
import { useActiveWeb3React } from './web3'
import { FramesContractDetails } from '../lib/constants'
import { getTokenMetadata } from '../usecase/GetTokenMetadataUseCase'

export const useGetFrameTokenFullMetadata = (tokenId, immediate = true) => {
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState(null)
  const [error, setError] = useState(null)
  const { library } = useActiveWeb3React()
  const murallFrameContract = useMurAllFrameContract()

  const getFrameMetadata = () => {
    return new Promise(async function (resolve, reject) {
      let traitHash
      try {
        traitHash = await murallFrameContract.methods.getTraits(tokenId).call()
        traitHash = library.utils.padLeft(
          library.utils.numberToHex(traitHash),
          64
        )
      } catch (e) {
        console.error(e)
        reject(e)
      }

      const returnNft = {
        contract: FramesContractDetails,
        tokenID: tokenId,
        traitHash: traitHash
      }

      try {
        const theMetadata = await getTokenMetadata(
          library,
          FramesContractDetails.id,
          tokenId,
          1
        )

        resolve({ frame: returnNft, metadata: theMetadata })
      } catch (e) {
        console.error(e)
        reject(e)
      }
    })
  }

  const execute = useCallback(async () => {
    if (!murallFrameContract) return
    setIsLoading(true)
    setValue(null)
    setError(null)

    let result

    try {
      result = await getFrameMetadata()

      setValue(result)
    } catch (err) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
    return result
  }, [murallFrameContract, tokenId])

  useEffect(() => {
    if (immediate) {
      execute()
    }
  }, [execute, immediate])
  return { execute, isLoading, value, error }
}

export default useGetFrameTokenFullMetadata
