import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import React, { useState } from 'react'
import { checkAndGetTokenMetadata } from '../../../usecase/CheckAndGetTokenMetadataUseCase'
import { isValidAddress } from '../../../usecase/IsValidAddressForChainUsecase'
import { useWeb3React } from '@web3-react/core'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Fab from '@mui/material/Fab'
import { green } from '@mui/material/colors'
import { Typography } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import getReadOnlyLibrary from '../../../lib/web3/getReadOnlyLibrary'
import Button from '@mui/material/Button'

export default function TokenInput(props) {
  const { account, library } = useWeb3React()

  const [contractAddress, setContractAddress] = useState(
    props.overrideContractAddress
  )
  const [contractAddressError, setContractAddressError] = useState(false)
  const [tokenIdError, setTokenIdError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [tokenId, setTokenId] = useState(null)
  const [loading, setLoading] = useState(false)

  const checkNft = async () => {
    setErrorMessage('')
    setLoading(true)
    const libToUse =
      props.chainId !== 1 ? getReadOnlyLibrary(props.chainId) : library
    try {
      const info = await checkAndGetTokenMetadata(
        libToUse,
        account,
        contractAddress,
        tokenId,
        props.chainId
      )
      props.onTokenSelected &&
        props.onTokenSelected(
          contractAddress,
          tokenId,
          info.nftData,
          info.nftMetadata.metadata,
          props.chainId
        )
    } catch (error) {
      console.error(error)
      let message
      if (
        error.message.indexOf('ERC721: owner query for nonexistent token') !==
        -1
      ) {
        setTokenIdError(true)
        message = 'Token does not exist!'
      } else if (
        error.message.indexOf(
          'you are not using the correct ABI for the contract'
        ) !== -1
      ) {
        setContractAddressError(true)
        message = 'Contract is not supported'
      } else if (
        error.message.indexOf(`You don't own any of these tokens`) !== -1
      ) {
        setTokenIdError(true)
        message = error.message
      } else {
        setContractAddressError(true)
        message = error.message
      }
      setErrorMessage(message)
    }

    setLoading(false)
  }

  return (
    <Paper
      component="form"
      sx={{
        p: '12px',
        ...(props.large && { p: '24px' }),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        ...(props.sx
          ? {
              ...props.sx
            }
          : {})
      }}
    >
      {props.large && (
        <Typography
          variant={'h5'}
          component="p"
          align="left"
          color={'inherit'}
          sx={{
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 300,
            textShadow: '2px 2px 5px black'
          }}
        >
          Token Details
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: props.large ? 'column' : 'row',
          // flex: 1,
          width: '100%',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: props.large ? 'column' : 'row',
            ...(props.large && { width: '100%' }),
            flex: 1,
            alignItems: props.large ? 'flex-start' : 'center',
            alignContent: props.large ? 'flex-start' : 'center',
            justifyContent: props.large ? 'flex-start' : 'center'
          }}
        >
          {!props.overrideContractAddress && (
            <TextField
              label="Contract Address"
              variant="standard"
              sx={{
                ...(!props.large && { ml: '12px', flex: 1 }),
                ...(props.large && { width: '100%', pb: '24px' })
              }}
              error={contractAddressError}
              placeholder="Contract Address"
              inputProps={{
                'aria-label': 'contract address',
                pattern: '^0x[a-fA-F0-9]{40}$'
              }}
              onChange={event => {
                const valid = isValidAddress(event.target.value, props.chainId)

                setContractAddressError(event.target.value ? !valid : false)
                setErrorMessage(
                  event.target.value
                    ? !valid
                      ? 'Enter Valid Contract Address'
                      : ''
                    : ''
                )
                setContractAddress(event.target.value)
              }}
            />
          )}

          {!props.large && !props.overrideContractAddress && (
            <Divider sx={{ height: 38, m: 1 }} orientation="vertical" />
          )}
          <TextField
            sx={{
              ...(props.large && { width: '100%', pb: '24px' }),
              ...(!props.large && { flex: 1 }),
              ...(props.overrideContractAddress && { ml: 1 })
            }}
            placeholder="Token Id"
            label={props.tokenIdLabel || 'Token Id'}
            type="number"
            error={tokenIdError}
            variant="standard"
            inputProps={{ 'aria-label': 'token id' }}
            onChange={event => {
              setTokenIdError(false)
              if(props.overrideContractAddress) {
                setContractAddressError(false)
                setErrorMessage('')
              }
              setTokenId(event.target.value)
            }}
          />
          {!props.large && (
            <Divider sx={{ height: 38, m: 1 }} orientation="vertical" />
          )}
          <Box
            sx={{
              position: 'relative',
              ...(props.large && { width: '100%', pb: '24px' })
            }}
          >
            {props.large ? (
              <>
                <Button
                  fullWidth
                  variant="contained"
                  disabled={
                    loading ||
                    contractAddressError ||
                    !tokenId ||
                    !contractAddress
                  }
                  onClick={checkNft}
                >
                  Use Token For frame contents
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px'
                    }}
                  />
                )}
              </>
            ) : (
              <>
                <Fab
                  size="small"
                  aria-label="save"
                  color="primary"
                  disabled={
                    loading ||
                    contractAddressError ||
                    !tokenId ||
                    !contractAddress
                  }
                  onClick={checkNft}
                >
                  <KeyboardArrowRightIcon />
                </Fab>
                {loading && (
                  <CircularProgress
                    size={52}
                    sx={{
                      color: green[500],
                      position: 'absolute',
                      top: -6,
                      left: -6,
                      zIndex: 1
                    }}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Collapse in={errorMessage}>
        <Typography
          variant={'subtitle2'}
          component="p"
          align="center"
          color={'error'}
          sx={{
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 300,
            mt: 1,
            textShadow: '2px 2px 5px black'
          }}
        >
          {errorMessage}
        </Typography>
      </Collapse>
    </Paper>
  )
}
