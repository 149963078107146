import Paper from '@mui/material/Paper'
import React, { useState } from 'react'
import { IconButton, InputBase } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

export default function FrameTokenInput (props) {
  const [tokenIdError, setTokenIdError] = useState(false)
  const [tokenId, setTokenId] = useState(null)

  return (
    <Paper
      component='form'
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        ...(props.sx
          ? {
              ...props.sx
            }
          : {})
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        type='number'
        placeholder={`Go To Frame (0 - ${props.maxTokenId})`}
        inputProps={{ 'aria-label': 'go to frame' }}
        onChange={event => {
          setTokenIdError(false)
          setTokenId(event.target.value)
        }}
        error={tokenIdError}
      />
      <IconButton
        type='submit'
        sx={{ p: '10px' }}
        aria-label='search'
        onClick={() => props.onGoToTokenDetails(tokenId)}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}
