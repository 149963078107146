import {
  IPFS_GATEWAY_CLOUDFLARE,
  IPFS_GATEWAY_PINATA_MURALL
} from '../lib/helpers'
import { getTokenMetadata } from './GetTokenMetadataUseCase'
import { processTokenMetadata } from './ProcessTokenMetadataUseCase'

const FRAMES_CONTRACT_ADDRESS = '0x785B0CEb43d35ff40e24Ef863a0C7d400a96e3A2'

export const getAndProcessTokenMetadata = async (
  web3,
  contractAddress,
  tokenId,
  chainId = 1,
  fetchDimensions = false,
  preferAnimation = false,
  preferredIpfsGateway = IPFS_GATEWAY_CLOUDFLARE
) => {
  let metadata

  if (contractAddress) {
    try {
      metadata = await getTokenMetadata(web3, contractAddress, tokenId, chainId)
    } catch (e) {
      console.error(
        'error fetching metadata for ' +
          contractAddress +
          ' token ' +
          tokenId +
          ' chain ' +
          chainId,
        e
      )
    }

    if (!metadata) {
      throw new Error(
        'Error fetching metadata for ' +
          contractAddress +
          ' token ' +
          tokenId +
          ' chain ' +
          chainId
      )
    }

    if (
      contractAddress.toUpperCase() === FRAMES_CONTRACT_ADDRESS.toUpperCase()
    ) {
      preferredIpfsGateway = IPFS_GATEWAY_PINATA_MURALL
    }

    return processTokenMetadata(
      metadata,
      fetchDimensions,
      preferAnimation,
      preferredIpfsGateway
    )
  } else {
    return processTokenMetadata(
      null,
      fetchDimensions,
      preferAnimation,
      preferredIpfsGateway
    )
  }
}
