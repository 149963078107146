import React from 'react'
import styled from 'styled-components'
import { Button } from '@mui/material'
import CropOriginalIcon from '@mui/icons-material/CropOriginal'

const PresaleMintButton = () => {
  return (
    <StyledButton
      variant='contained'
      color='secondary'
      disableElevation
      fullWidth
      align='center'
      endIcon={<CropOriginalIcon />}
      component='a'
      href='/mint'
    >
      Mint you frame now
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`

export default PresaleMintButton
