import React, { useEffect, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useWeb3React } from '@web3-react/core'
import { Grid } from '@mui/material'
import { useTheme } from '@mui/styles'
import { constructFrameNftWithBaseSvg } from '../../../usecase/ConstructFrameNftWithBaseSvgUsecase'
import { constructBaseSvgStringForTraitHash } from '../../../usecase/ConstructBaseSvgStringForTraitHashUsecase'
import LoadingAnimation from '../loading/LoadingAnimation'
import {
  replaceIpfsIfNeeded,
  getSvgCodeFromFile,
  IPFS_GATEWAY_PINATA_MURALL
} from '../../../lib/helpers.js'
import { CHAIN_INFO_BY_NAME } from '../../../lib/constants'
import DimensionInput from './DimensionInput'
import getReadOnlyLibrary from '../../../lib/web3/getReadOnlyLibrary'
import useGetFrameTokenMetadata from '../../../hooks/useGetFrameTokenMetadata'

export default function MountedContentPreview(props) {
  const {
    execute,
    isLoading,
    value: fetchedFrameMetadata,
    error
  } = useGetFrameTokenMetadata(props.selectedFrame.tokenID)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const [loading, setLoading] = useState(false)
  const [loadingBase, setLoadingBase] = useState(false)
  const [baseSvg, setBaseSvg] = useState(null)
  const [mountedSvg, setMountedSvg] = useState('')
  const [unmountedSvg, setUnmountedSvg] = useState('')
  const [frameDimensions, setFrameDimensions] = useState(null)
  const [manualDimensionInput, setManualDimensionInput] = useState(false)

  const { account, library } = useWeb3React()

  const getOverrideMetadataIfPresent = () => {
    let overrideMetadata
    if (
      props.selectedNft.token_metadata &&
      props.selectedNft.token_metadata.indexOf(
        'data:application/json;base64'
      ) !== -1
    ) {
      overrideMetadata = JSON.parse(
        Buffer.from(
          props.selectedNft.token_metadata.replace(
            'data:application/json;base64',
            ''
          ),
          'base64'
        )
      )
    }
    console.log('overrideMetadata', overrideMetadata)
    return overrideMetadata
  }

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    async function onLoad() {
      if (
        !library ||
        !baseSvg ||
        loadingBase ||
        !props.selectedNft ||
        loading
      ) {
        return
      }
      if (signal.aborted) {
        return
      }

      let svgData
      setLoading(true)
      try {
        if (signal.aborted) {
          return
        }
        const libToUse =
          props.chainId !== 1
            ? props.chainId !== CHAIN_INFO_BY_NAME.Solana.id
              ? getReadOnlyLibrary(props.chainId)
              : null
            : library
        let overrideMetadata = getOverrideMetadataIfPresent()

        svgData = await constructFrameNftWithBaseSvg(
          libToUse,
          props.selectedNft.contract.id,
          props.selectedNft.tokenID,
          props.chainId,
          baseSvg,
          null,
          overrideMetadata
        )
      } catch (e) {
        console.error(
          'Error fetching ' +
            props.selectedNft.contract.id +
            ' ' +
            props.selectedNft.tokenID,
          e
        )
        if (signal.aborted) {
          return
        }
        svgData = await constructFrameNftWithBaseSvg(
          null,
          null,
          null,
          props.chainId,
          baseSvg
        )
      }
      if (signal.aborted) {
        return
      }

      setManualDimensionInput(svgData.failedDimensionDetection)

      setFrameDimensions({ width: svgData.width, height: svgData.height })
      setMountedSvg('<svg width="100%" height="100%">' + svgData.svg + '</svg>')
      setLoading(false)
    }

    onLoad()
    // Remove event listener on cleanup
    return () => controller.abort()
  }, [library, loadingBase, props.selectedNft])
  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    async function onLoad() {
      if (
        !library ||
        !baseSvg ||
        loadingBase ||
        !props.selectedNft ||
        loading
      ) {
        return
      }
      if (signal.aborted) {
        return
      }

      let svgData
      setLoading(true)
      try {
        if (signal.aborted) {
          return
        }
        const libToUse =
          props.chainId !== 1
            ? props.chainId !== CHAIN_INFO_BY_NAME.Solana.id
              ? getReadOnlyLibrary(props.chainId)
              : null
            : library
        let overrideMetadata = getOverrideMetadataIfPresent()
        svgData = await constructFrameNftWithBaseSvg(
          libToUse,
          props.selectedNft.contract.id,
          props.selectedNft.tokenID,
          props.chainId,
          baseSvg,
          frameDimensions,
          overrideMetadata
        )
      } catch (e) {
        console.error(
          'Error fetching ' +
            props.selectedNft.contract.id +
            ' ' +
            props.selectedNft.tokenID,
          e
        )
        if (signal.aborted) {
          return
        }
        svgData = await constructFrameNftWithBaseSvg(
          null,
          null,
          null,
          props.chainId,
          baseSvg,
          frameDimensions
        )
      }
      if (signal.aborted) {
        return
      }

      setMountedSvg('<svg width="100%" height="100%">' + svgData.svg + '</svg>')
      setLoading(false)
    }

    onLoad()
    // Remove event listener on cleanup
    return () => controller.abort()
  }, [frameDimensions])

  useEffect(() => {
    if (isLoading || loadingBase) {
      return
    }
    async function constructBaseSvgStringWithFetchedTraitHash(
      traitHash,
      signal
    ) {
      let baseSvgString
      try {
        baseSvgString = await constructBaseSvgStringForTraitHash(
          traitHash,
          signal
        )
        if (signal.aborted) {
          return
        }
        setBaseSvg(baseSvgString)
      } catch (e) {
        console.error('Error fetching ' + traitHash, e)
        if (signal.aborted) {
          return
        }
      }
    }
    if (fetchedFrameMetadata && !baseSvg && !loadingBase) {
      setLoadingBase(true)

      const controller = new AbortController()
      const { signal } = controller

      constructBaseSvgStringWithFetchedTraitHash(
        fetchedFrameMetadata.traitHash,
        signal
      ).then(() => {
        setLoadingBase(false)
      })
    }
  }, [isLoading, fetchedFrameMetadata, baseSvg, loadingBase])
  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    async function onLoad() {
      setLoadingBase(true)
      let baseSvgString
      if (signal.aborted) {
        return
      }

      if (props.selectedFrame.traitHash) {
        try {
          baseSvgString = await constructBaseSvgStringForTraitHash(
            props.selectedFrame.traitHash,
            signal
          )
          if (signal.aborted) {
            return
          }
          setBaseSvg(baseSvgString)
        } catch (e) {
          console.error('Error fetching ' + props.selectedFrame.traitHash, e)
          if (signal.aborted) {
            return
          }
        }
      } else if (!isLoading) {
        execute()
      }

      if (props.selectedFrameMetadata) {
        let svgCode = await getSvgCodeFromFile(
          replaceIpfsIfNeeded(
            props.selectedFrameMetadata.animation_url,
            IPFS_GATEWAY_PINATA_MURALL
          )
        )

        const substr = svgCode.substring(
          svgCode.indexOf('<svg'),
          svgCode.lastIndexOf('</svg>') + 6
        )

        setUnmountedSvg(substr)
      }
      setLoadingBase(false)
    }

    onLoad()
    // Remove event listener on cleanup
    return () => controller.abort()
  }, [props.selectedFrame]) // Empty array ensures that effect is only run on mount

  const createImage = frame => (
    <span
      dangerouslySetInnerHTML={{
        __html: frame
      }}
    />
  )

  return (
    <Grid
      sx={{
        ...(props.sx
          ? {
              ...props.sx
            }
          : {})
      }}
      container
      spacing={2}
      direction={'column'}
      justifyContent="space-between"
      alignItems="stretch"
    >
      {loadingBase || loading || isLoading ? (
        <Grid
          container
          item
          xs
          direction={'row'}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs>
            <LoadingAnimation />
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid item xs>
            {createImage(props.selectedNft ? mountedSvg : unmountedSvg)}
          </Grid>
          {manualDimensionInput && (
            <Grid item xs={1}>
              <DimensionInput
                manualInputDisabled={!manualDimensionInput}
                frameWidth={frameDimensions.width}
                frameHeight={frameDimensions.height}
                onUpdateDimensions={(width, height) => {
                  setFrameDimensions({ width: width, height: height })
                  props.onUpdateDimensions &&
                    props.onUpdateDimensions(width, height)
                }}
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}
