import React, { useState } from 'react'
import { useLazyEffect } from '../../hooks/useLazyEffect'
import { VirtuosoGrid } from 'react-virtuoso'
import styled from 'styled-components'

const ItemContainer = styled.div`
  padding: 0.5rem;
  aspect-ratio: 1/1.1;
  width: 25%;
  display: flex;
  flex: none;
  align-content: stretch;
  box-sizing: border-box;

  @media (max-width: 1536px) {
    width: 25%;
  }
  @media (max-width: 1200px) {
    width: 33%;
  }

  @media (max-width: 1024px) {
    width: 33%;
  }

  @media (max-width: 900px) {
    width: 50%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  @media (max-width: 300px) {
    width: 100%;
  }
`

const ItemWrapper = styled.div`
  flex: 1;
  aspect-ratio: 1/1.1;
  text-align: center;
  font-size: 80%;
  padding: 1rem 1rem;
  border: 1px solid var(gray);
  white-space: nowrap;
`

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const VirtualizedGrid = ({
  style,
  totalCount,
  loadingViewForIndex,
  itemViewForIndex,
  onVisibleRangeChanged,
  overscan = 20
}) => {
  const [visibleRange, setVisibleRange] = useState({
    startIndex: 0,
    endIndex: 0
  })

  useLazyEffect(() => {
    if (!visibleRange || !visibleRange.startIndex || !visibleRange.endIndex) {
      return
    }
    console.log('visibleRange changed', visibleRange)
    onVisibleRangeChanged(visibleRange.startIndex, visibleRange.endIndex)
  }, [visibleRange])

  return (
    <VirtuosoGrid
      style={style}
      totalCount={totalCount}
      overscan={overscan}
      components={{
        Item: ItemContainer,
        List: ListContainer,
        ScrollSeekPlaceholder: ({ height, width, index }) => (
          <ItemContainer>
            <ItemWrapper>{loadingViewForIndex(index)}</ItemWrapper>
          </ItemContainer>
        )
      }}
      itemContent={index => {
        return <ItemWrapper>{itemViewForIndex(index)}</ItemWrapper>
      }}
      scrollSeekConfiguration={{
        enter: velocity => {
          return Math.abs(velocity) > 700
        },
        exit: velocity => {
          return Math.abs(velocity) < 10
        },
        change: (_, range) => {
          console.log('range', range)
          setVisibleRange(range)
        }
      }}
    />
  )
}

export default VirtualizedGrid
