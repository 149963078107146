import { constructBaseSvgString } from './ConstructBaseSvgStringUsecase.js'
import { decodeFrameTraits } from './DecodeFrameTraitsUsecase'

export const constructBaseSvgStringForTraitHash = async (
  traitHash,
  abortSignal
) => {
  const frameData = await decodeFrameTraits(traitHash, abortSignal)
  if (abortSignal && abortSignal.aborted) {
    throw new Error('Aborted')
  }
  const baseSvgString = constructBaseSvgString(
    frameData.main,
    frameData.accent,
    frameData.secondaryAccent,
    frameData.tiledPiece,
    frameData.cornerPiece,
    frameData.tileRim,
    frameData.cornerRim,
    frameData.tileEffect,
    frameData.cornerEffect,
    frameData.scrollingRimAnimation,
    frameData.scrollingRimAnimationReverse,
    frameData.animation,
    frameData.plaque,
    frameData.plaqueContents
  )

  return baseSvgString
}
