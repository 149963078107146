import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import RefreshIcon from '@mui/icons-material/Refresh'
import React, { useState } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Fab from '@mui/material/Fab'
import { green } from '@mui/material/colors'
import { Typography } from '@mui/material'

export default function DimensionInput(props) {
  const [frameWidth, setFrameWidth] = useState(props.frameWidth)
  const [frameHeight, setFrameHeight] = useState(props.frameHeight)

  const [loading, setLoading] = useState(false)

  return (
    <Paper
      component="form"
      sx={{
        p: '12px 0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...(props.sx
          ? {
              ...props.sx
            }
          : {})
      }}
    >
      <Typography
        variant={'subtitle2'}
        component="p"
        align="center"
        color={'info'}
        sx={{
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontWeight: 300,
          mb: 1,
          textShadow: '2px 2px 5px black'
        }}
      >
        Please adjust the dimensions manually to fit contents:
      </Typography>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flex: 1,
          alignItems: 'center'
        }}
      >
        <TextField
          label="Width"
          placeholder="Width"
          variant="standard"
          value={frameWidth}
          sx={{ ml: '12px', flex: 1 }}
          type="number"
          inputProps={{
            'aria-label': 'Width'
          }}
          onChange={event => {
            setFrameWidth(event.target.value)
          }}
        />

        <Divider sx={{ height: 38, m: 1 }} orientation="vertical" />
        <TextField
          sx={{ flex: 1 }}
          placeholder="Height"
          label="Height"
          value={frameHeight}
          type="number"
          variant="standard"
          inputProps={{ 'aria-label': 'height' }}
          onChange={event => {
            setFrameHeight(event.target.value)
          }}
        />

        <Divider sx={{ height: '100%', m: 1 }} orientation="vertical" />

        <Box sx={{ mr: 1, position: 'relative' }}>
          <Fab
            aria-label="save"
            color="primary"
            disabled={!frameWidth || !frameHeight}
            onClick={async () => {
              props.onUpdateDimensions(frameWidth, frameHeight)
            }}
          >
            <RefreshIcon />
          </Fab>
          {loading && (
            <CircularProgress
              size={68}
              sx={{
                color: green[500],
                position: 'absolute',
                top: -6,
                left: -6,
                zIndex: 1
              }}
            />
          )}
        </Box>
      </Box>
    </Paper>
  )
}
