import React from 'react'
import styled from 'styled-components'
import Typography from '@mui/material/Typography'
import Container from './Minting/Forms/Container'
import Background from './Minting/Forms/Background'
import useMediaQuery from '@mui/material/useMediaQuery'
import NYRevLogo from '../../assets/images/nyrevolution/NYRev_cropped.png'

const NewYearsRevolutionInfo = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return (
    <Container glow={!isMobile}>
      <Background
        sx={{
          maxWidth: 450
        }}
      >
        <img
          src={NYRevLogo}
          alt={'New Years Revolution'}
          style={{
            height: 95,
            minWidth: '65px',
            marginBottom: 20,
            objectPosition: 'center',
            objectFit: 'contain',
            verticalAlign: 'text-top'
          }}
        />
        <Typography variant="body1" component="p" mb={1} align="center">
          We're hosting a raffle for{' '}
          <Typography
            display="inline"
            variant="body1"
            component="p"
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 500
            }}
          >
            1 ETH + 5 million $PAINT
          </Typography>
          , where every FRAME minted will include entrance into the raffle
          (meaning the more you mint the higher your chances!)
          <br />
          <br />
          Minted previously? No problem! If you mint at least 1 FRAME during the
          raffle period, your previously minted frames will be counted as
          entries in the raffle as well!
          <br />
          <br />
          <Link
            href="https://murall.art/newyearsrevolution"
            target="_blank"
            rel="noreferrer"
          >
            More info on the New Years Revolution
          </Link>
        </Typography>
      </Background>
    </Container>
  )
}

const Link = styled.a`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`

export default NewYearsRevolutionInfo
