import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { Web3ReactProvider, createWeb3ReactRoot } from '@web3-react/core'

import './index.css'
import store from './state/store'
import Routes from './routes'
import ThemeProvider from './theme/provider'
import Web3ReactManager from './components/common/Web3ReactManager'
import Notifications from './components/common/notifications'
import getLibrary from './lib/web3/getLibrary'
import { FALLBACK_PROVIDER_NAME } from './lib/constants'

const Web3ProviderFallback = createWeb3ReactRoot(FALLBACK_PROVIDER_NAME)

TagManager.initialize({
  gtmId: 'GTM-TC3MQLL'
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store()}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderFallback getLibrary={getLibrary}>
          <ThemeProvider>
            <Web3ReactManager>
              <Notifications />
              <Router>
                <Routes />
              </Router>
            </Web3ReactManager>
          </ThemeProvider>
        </Web3ProviderFallback>
      </Web3ReactProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
