import React from 'react'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import SectionTitle from '../components/common/SectionTitle'
import Accordion from '../components/common/Accordion'
import WidthLimiter from '../components/common/WidthLimiter'

const FAQ_ITEMS = [
  {
    title: 'What are Frames?',
    content:
      'Frames by MurAll are a collection of 4,444 revolutionary NFTs which allow collectors to mount their existing artworks (even across different blockchains!) in a decorative, rare and highly collectible frame. Using our expertise in technology and design, Frames strike the delicate balance between functionality and beauty. A Frame NFT can wrap around NFTs of all sizes and aspect ratios, meaning images will always fit perfectly within the frame. Frames can also be bound with a chosen NFT on Ethereum to further compound their rarity, allowing them to be sold or transferred as a single NFT.'
  },
  {
    title: 'How are Frames generated?',
    content:
      'Unlike other NFT projects, Frames will utilise an oracle solution to generate truly random attributes in a fair way. Each trait will determine a visual aspect of the Frame.'
  },
  // {
  //   title: 'How are Frames launched?',
  //   content:
  //     'Frames are launched in waves of 252, until the max supply is reached. The first 4 waves will be the presale waves, meaning only addresses whitelisted for the presale will be allowed to mint. Following waves are public, meaning anyone can mint. The dates for the waves will be announced on our social channels as time goes on.'
  // },
  // {
  //   title: 'Will there be a presale?',
  //   content:
  //     'Yes. The first 4 waves are presale waves, where only eligible addresses are able to mint 2 Frames each. A maximum of 1008 FRAMES will be available for 0.144 ETH each.'
  // },
  // {
  //   title: 'When is the launch?',
  //   content:
  //     'The first presale wave will take place on November 24th 2021 at 4pm UTC.'
  // },
  {
    title: 'What is the mint cost?',
    content: '0.015 ETH + network transaction fees'
  },
  {
    title: 'Will Frames work with MurAll?',
    content:
      'Yes, Frame owners will be able to assign their Frame when using the MurAll dApp to view their personal gallery, history and live state sections with their chosen Frame.'
  },
  {
    title: 'Which chains are supported for cross-chain mounting?',
    content:
      'The current supported chains for cross chain mounting are: Ethereum, Polygon, Binance Smart Chain and Solana.'
  },
  {
    title: 'Will support for more chains be added?',
    content:
      'Yes, we will be adding support for more chains as we continue to develop our Frames ecosystem.'
  },
  {
    title: 'What is the maximum supply of FRAMES?',
    content: '4444'
  }
  // {
  //   title: 'How can I get whitelisted for the presale waves?',
  //   content: (
  //     <>
  //       <p>There are 502 spots on the presale whitelist.</p>
  //       <p>
  //         1) 30 spots are reserved for the invite contest winners on our Discord
  //       </p>
  //       <p>
  //         2) 30 spots will be distributed via random giveaways on our Discord
  //       </p>
  //       <p>
  //         3) The team reserves 32 spots to name the most deserving community
  //         members
  //       </p>
  //       <p>
  //         4) 50 spots for people who have burned the most $PAINT and have filled{' '}
  //         <FormLink text='the form' /> to express interest
  //       </p>
  //       <p>
  //         5) 360 spots will be decided with a lottery between $PAINT holders who
  //         have filled <FormLink text='the form' /> to express interest (holding
  //         more paint will give you a better chance)
  //       </p>
  //     </>
  //   )
  // }
]

const FaqSection = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <WidthLimiter>
      <Container noPadding={isMobile}>
        <SectionTitle title="FAQ" />
        <Accordion items={FAQ_ITEMS} />
      </Container>
    </WidthLimiter>
  )
}

const Container = styled.div`
  display: flex:
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-content: center;
  padding-top: ${({ theme }) => theme.spacing(6)};
  padding-bottom: ${({ theme }) => theme.spacing(16)};
  padding-left: ${({ theme, noPadding }) =>
    noPadding ? 0 : theme.spacing(16)};
  padding-right: ${({ theme, noPadding }) =>
    noPadding ? 0 : theme.spacing(16)};

  &:before {
    height: 100%;
    top: 0;
    background-image: radial-gradient(#e91bfa4d 0%, transparent 65%);
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    mix-blend-mode: screen;
    z-index: -100;
  }
`

export default FaqSection
