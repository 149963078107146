import React, { useState, useEffect } from 'react'
import FramesLogo from '../../../assets/images/logos/FRAME_small.png'
import { Typography, Box, CardActionArea, Avatar, Grid } from '@mui/material'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import { processTokenMetadata } from '../../../usecase/ProcessTokenMetadataUseCase'
import { useWeb3React } from '@web3-react/core'
import FramesLoadingCard from './FramesLoadingCard'
import { getTokenMetadata } from '../../../usecase/GetTokenMetadataUseCase'
import { replaceIpfsIfNeeded } from '../../../lib/helpers'

export default function FramesNftCard({
  nft,
  onItemClick = null,
  needsRefresh = false,
  sx = null
}) {
  const [metadata, setMetadata] = useState(null)
  const [displayImage, setDisplayImage] = useState(null)
  const [loading, setLoading] = useState(false)

  const { library } = useWeb3React()

  useEffect(() => {
    async function onLoad() {
      if (metadata && !needsRefresh) {
        return
      }
      let nftToFetch = nft
      if (library || nftToFetch.asset_contract) {
        setLoading(true)
        if (nftToFetch.asset_contract) {
          // the nft is from opensea and contains metadata already
          try {
            const processedMetadata = await processTokenMetadata(nftToFetch)
            setMetadata(nftToFetch)
            setDisplayImage(processedMetadata.display_url)
            setLoading(false)
          } catch (error) {
            console.error(error)
            setLoading(false)
          }
        } else {
          try {
            const theMetadata = await getTokenMetadata(
              library,
              nftToFetch.contract.id,
              nftToFetch.tokenID
            )

            setMetadata(theMetadata)
            setDisplayImage(replaceIpfsIfNeeded(theMetadata.image))
            setLoading(false)
          } catch (error) {
            console.error(error)
            setLoading(false)
          }
        }
      }
    }

    onLoad()
    // Remove event listener on cleanup
    return () => {}
  }, [library, nft, needsRefresh])

  return (
    <Box
      sx={{
        ...(sx
          ? {
              ...sx
            }
          : {})
      }}
    >
      {loading ? (
        <FramesLoadingCard text={`Frame #${nft.tokenID}`} />
      ) : (
        metadata && (
          <Card elevation={4}>
            <CardActionArea
              onClick={() => {
                onItemClick && onItemClick(nft, metadata)
              }}
            >
              <CardMedia
                sx={{
                  aspectRatio: '1/1',
                  objectFit: 'contain'
                }}
                component={'img'}
                src={displayImage}
                title={metadata.name}
                alt={metadata.name}
              />
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Avatar
                    alt="Frames"
                    src={FramesLogo}
                    variant="square"
                    sx={{
                      width: theme => theme.spacing(3),
                      height: theme => theme.spacing(3)
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{ p: 1.5, fontFamily: 'Roboto', fontWeight: 300 }}
                    variant="h6"
                    component="p"
                    color="textPrimary"
                  >
                    {metadata.name}
                  </Typography>
                </Grid>
              </Grid>
            </CardActionArea>
          </Card>
        )
      )}
    </Box>
  )
}
