import React, { useState, useEffect } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import Container from '../components/common/PageContainer'
import ConnectWalletModal from '../components/common/wallet/modal'
import Error from '../components/common/buttons/ErrorConnectWalletButton'
import ConnectWalletButton from '../components/common/buttons/ConnectWalletButton'
import MountPageContent from '../components/common/mounting/MountPageContent'
// import { useGetNftsQuery } from '../state/graphql'
import { useGetNftsQuery } from '../state/opensea'
import { useGetFrameTokenMetadataForAccountInRange } from '../hooks/useGetFrameTokenMetadataForAccountInRange'
import { switchToEthereum } from '../usecase/SwitchToEthereumUsecase'
const MountPage = () => {
  const [openModal, setOpenModal] = useState(false)
  return (
    <Container>
      <Content open={() => setOpenModal(true)} />
      <ConnectWalletModal
        isOpen={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </Container>
  )
}

const Content = ({ open }) => {
  const { account, error } = useWeb3React()

  if (error)
    return (
      <Error
        onClick={() => {
          open()
          switchToEthereum(account)
        }}
        text={
          error instanceof UnsupportedChainIdError
            ? 'Unsupported Network'
            : 'Error'
        }
      />
    )

  if (account) {
    return <MountContainer />
  }

  return <ConnectWalletButton onClick={open} text="Connect Your Wallet" />
}

const LOAD_LIMIT = 10

const MountContainer = () => {
  const { account } = useWeb3React()

  const [allNfts, setAllNfts] = useState([])
  const [errorLoadingNfts, setErrorLoadingNfts] = useState(null)
  const [errorLoadingFrames, setErrorLoadingFrames] = useState(null)
  const [allOwnedFramesIds, setAllOwnedFramesIds] = useState([])
  const [canFetchMore, setCanFetchMore] = useState(true)
  const [canFetchMoreFrames, setCanFetchMoreFrames] = useState(true)
  const [currentNftsFirstPosition, setCurrentNftsFirstPosition] = useState(0)
  const [currentFirstFramesPosition, setCurrentFirstFramesPosition] = useState(
    0
  )
  const { data, isFetching, error } = useGetNftsQuery(
    { address: account, from: currentNftsFirstPosition, limit: LOAD_LIMIT },
    { skip: !account ? !account : !canFetchMore }
  )

  const {
    execute: executeMetadata,
    isLoading: isLoadingTokenIds,
    value: framesTokensForRange,
    error: errorMetadata
  } = useGetFrameTokenMetadataForAccountInRange(
    currentFirstFramesPosition,
    LOAD_LIMIT,
    account,
    true
  )

  useEffect(() => {
    async function onLoad() {
      if (isLoadingTokenIds) return
      if (!framesTokensForRange) return
      if (!framesTokensForRange.length) {
        setCanFetchMoreFrames(false)
        if (allOwnedFramesIds.length === 0) {
          setErrorLoadingFrames('No Frames found')
        }
        return
      } else {
        setErrorLoadingFrames(null)
      }

      const newData = framesTokensForRange.map(nft => (nft.nft ? nft.nft : nft))
      const nftIds = allOwnedFramesIds.map(nft =>
        nft.contract.id.concat(nft.tokenID)
      )

      const newNfts = newData.filter(
        nft => !nftIds.includes(nft.contract.id.concat(nft.tokenID))
      )
      const combined = [...allOwnedFramesIds, ...newNfts]
      setAllOwnedFramesIds(combined)
      if (combined.length === 0) {
        setCanFetchMoreFrames(false)
        setErrorLoadingFrames('No Frames found')
      }
    }

    onLoad()

    return () => {}
  }, [isLoadingTokenIds, framesTokensForRange])

  //  subgraph query for nfts
  // useEffect(() => {
  //   async function onLoad () {
  //     if (isFetching) return
  //     if (error) {
  //       setErrorLoadingNfts('Error loading NFTs: ' + error.message)
  //       return
  //     }
  //     if (!data) return
  //     if (!data.length) {
  //       setCanFetchMore(false)
  //       if (allNfts.length === 0) {
  //         setErrorLoadingNfts('No NFTs found')
  //       }
  //       return
  //     } else {
  //       setErrorLoadingNfts(null)
  //     }

  //     const newData = data.map(nft => (nft.nft ? nft.nft : nft))
  //     const nftIds = allNfts.map(nft => nft.contract.id.concat(nft.tokenID))

  //     const newNfts = newData.filter(
  //       nft => !nftIds.includes(nft.contract.id.concat(nft.tokenID))
  //     )
  //     const combined = [...allNfts, ...newNfts]
  //     setAllNfts(combined)
  //     if (combined.length === 0) {
  //       setCanFetchMore(false)
  //       setErrorLoadingNfts('No NFTs found')
  //     }
  //   }

  //   onLoad()

  //   return () => {}
  // }, [isFetching, data, error])

  // opensea api query for nfts
  useEffect(() => {
    async function onLoad() {
      if (isFetching) return
      if (error) {
        setErrorLoadingNfts('Error loading NFTs: ' + error.message)
        return
      }
      if (!data) return
      if (!data.length) {
        setCanFetchMore(false)
        if (allNfts.length === 0) {
          setErrorLoadingNfts('No NFTs found')
        }
        return
      } else {
        setErrorLoadingNfts(null)
      }

      const newData = data.map(nft => (nft.nft ? nft.nft : nft))
      const nftIds = allNfts.map(nft => nft.contract.id.concat(nft.tokenID))

      const newNfts = newData.filter(
        nft => !nftIds.includes(nft.contract.id.concat(nft.tokenID))
      )
      const combined = [...allNfts, ...newNfts]
      setAllNfts(combined)
      if (combined.length === 0) {
        setCanFetchMore(false)
        setErrorLoadingNfts('No NFTs found')
      }
    }

    onLoad()

    return () => {}
  }, [isFetching, data, error])

  useEffect(() => {
    async function onLoad() {
      setAllNfts([])
      setAllOwnedFramesIds([])
      setErrorLoadingNfts(null)
      setErrorLoadingFrames(null)
      setCanFetchMore(true)
      setCanFetchMoreFrames(true)
      setCurrentFirstFramesPosition(0)
      setCurrentNftsFirstPosition(0)
      await executeMetadata()
    }

    onLoad()

    return () => {}
  }, [account])

  return (
    <MountPageContent
      nfts={allNfts}
      isFetchingNfts={isFetching}
      frames={allOwnedFramesIds}
      loadingViews={LOAD_LIMIT * 2}
      canFetchMore={canFetchMore}
      canFetchMoreFrames={canFetchMoreFrames}
      errorLoadingFrames={errorLoadingFrames}
      errorLoadingNfts={errorLoadingNfts}
      onRequestNext={lastIndex => {
        setCurrentNftsFirstPosition(lastIndex)
      }}
      onRequestNextFrames={lastIndex => {
        setCurrentFirstFramesPosition(lastIndex)
      }}
    />
  )
}

export default MountPage
