import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const BASE_URL = 'https://api.opensea.io/api'

export const OpenseaApi = createApi({
  reducerPath: 'openseaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/v1`
  }),
  endpoints: builder => ({
    getNfts: builder.query({
      query: ({ address, from = 0, limit = 10 }) => ({
        url: `assets?owner=${address}&order_direction=desc&offset=${from}&limit=${limit}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-API-KEY': '2522df2fe8804723a43ee4e63410f374'
        }
      }),
      transformResponse: response => {
        const newData = response.assets.map(nft => {
          nft.tokenID = nft.token_id
          nft.contract = {
            id: nft.asset_contract.address,
            name: nft.asset_contract.name,
            symbol: nft.asset_contract.symbol,
            type: nft.asset_contract.schema_name
          }

          return nft
        })

        return newData
      }
    }),
    getNftsForCollection: builder.query({
      query: ({ from = 0, limit = 10, contractAddress, tokenIds = [] }) => {
        let url = `assets?order_direction=asc&limit=${limit}&asset_contract_address=${contractAddress}`
        if (tokenIds.length > 0) {
          for (let i = 0; i < tokenIds.length; i++) {
            url = url.concat(`&token_ids=${tokenIds[i]}`)
          }
        } else {
          url += `&offset=${from}`
        }

        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'X-API-KEY': '2522df2fe8804723a43ee4e63410f374'
          }
        }
      },
      transformResponse: response => {
        const newData = response.assets.map(nft => {
          nft.tokenID = nft.token_id
          nft.contract = {
            id: nft.asset_contract.address,
            name: nft.asset_contract.name,
            symbol: nft.asset_contract.symbol,
            type: ''
          }
          return nft
        })

        return newData
      }
    }),
    getNft: builder.query({
      query: ({ contractAddress, tokenId }) => {
        let url = `asset/${contractAddress}/${tokenId}/?include_orders=true`

        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'X-API-KEY': '2522df2fe8804723a43ee4e63410f374'
          }
        }
      },
      transformResponse: response => {
        const newData = response.assets.map(nft => {
          nft.tokenID = nft.token_id
          nft.contract = {
            id: nft.asset_contract.address,
            name: nft.asset_contract.name,
            symbol: nft.asset_contract.symbol,
            type: ''
          }
          console.log('nft', nft)
          return nft
        })

        return newData
      }
    })
  })
})

export const {
  useGetNftsQuery,
  useGetNftQuery,
  useGetNftsForCollectionQuery
} = OpenseaApi
